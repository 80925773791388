.ReactModal__Body--open {
  overflow: hidden !important;
}
.ReactModal__Overlay {
  background-color: rgba(14, 13, 13, 0.75) !important;
}
.ReactModal__Overlay .ReactModal__Content {
  top: 15vh !important;
  border: 0 !important;
  background: transparent !important;
  border-radius: 0 !important;
  padding: 0 !important;
  width: 528px;
  margin: auto;
  height: auto;
}
.ReactModal__Overlay .ReactModal__Content .top-head-modal {
  background: #fdf7ea;
  border: 1px solid #f7dea8;
}
.ReactModal__Overlay .ReactModal__Content .top-head-modal .order-id {
  color: #5087c7;
  font-size: 22pt;
  font-family: "Roboto-Medium";
  margin: 0;
  padding: 20px 40px 10px;
  font-weight: normal;
}
.ReactModal__Overlay .ReactModal__Content .top-head-modal .order-details {
  color: #f7ae00;
  font-size: 16pt;
  margin: 0;
  padding: 0 40px 20px;
}
.ReactModal__Overlay .ReactModal__Content .reject-reason-form {
    width: 512px;
    margin: 0 auto;
    background: #fff;
    display: block;
}
.ReactModal__Overlay .ReactModal__Content .reject-reason-form label {
    display: block;
    padding: 40px 40px 20px;
    color: #1e1e1e;
    font-size: 18pt;
    font-family: "Roboto-Medium";
}
.ReactModal__Overlay .ReactModal__Content .reject-reason-form textarea {
    display: block;
    padding: 0;
    color: #1e1e1e;
    font-size: 16pt;
    border: 0;
    margin: 0 40px 20px;
    width: calc(100% - 80px);
    height: 80px;
    font-family: "Roboto-Regular";
}
.ReactModal__Overlay .ReactModal__Content .reject-reason-form textarea:focus {
    outline: none;
}
.ReactModal__Overlay .ReactModal__Content .reject-reason-form textarea::placeholder {
    color: #b4b4b4;
}
.ReactModal__Overlay .ReactModal__Content .reject-reason-form .btn-section {
    padding: 0 40px 40px;
    display: flex;
    justify-content: space-around;
}
.ReactModal__Overlay .ReactModal__Content .reject-reason-form .btn-section button {
    font-size: 20pt;
    font-family: 'Roboto-Medium';
    border: 0;
    background: #dbdbdb;
    width: 50%;
    margin: 0 10px;
    border-radius: 40px;
    padding: 10px 0;
}
.ReactModal__Overlay .ReactModal__Content .reject-reason-form .btn-section button:focus {
    outline: none;
}
.ReactModal__Overlay .ReactModal__Content .reject-reason-form .btn-section button:hover {
    cursor: pointer;
}
.ReactModal__Overlay .ReactModal__Content .reject-reason-form span.rejecterrormsg {
    display: block;
    padding: 0 40px 20px;
    color: red;
}