.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.login-page-inner {
  width: 440px;
}
.login-page-inner .logo {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.login-page-inner .logo p {
  font-size: 16pt;
  color: #3a5100;
  margin: 0;
  width: 100%;
  display: block;
  text-align: center;
  padding: 30px 0;
}
.Login-form {
  padding: 30px 0;
}

/****  floating-Lable style start ****/
.floating-form {
  width: 95%;
  margin: 0 auto;
}
.floating-label {
  position: relative;
  margin-bottom: 20px;
}
.floating-input {
  font-size: 16pt;
  padding: 20px 25px;
  display: block;
  width: 100%;
  height: 60px;
  background-color: transparent;
  border: none;
  border: 2px solid #8b8f9f;
  border-radius: 10px;
}

.floating-input:focus,
.floating-select:focus {
  outline: none;
  border: 2px solid #4f8bc8;
}
.label_float {
  color: #4f8bc8;
  font-size: 16pt;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 27px;
  top: 15px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.floating-input:focus ~ .label_float,
.floating-input:not(:placeholder-shown) ~ .label_float {
  top: -11px;
  font-size: 14pt;
  color: #4f8bc8;
  background: #fff;
  padding: 0 10px;
}
.floating-select:focus ~ .label_float,
.floating-select:not([value=""]):valid ~ .label_float {
  top: -18px;
  font-size: 14px;
  color: #5264ae;
}
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
/* animation */
@-webkit-keyframes inputHighlighter {
  from {
    background: #fff;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-moz-keyframes inputHighlighter {
  from {
    background: #fff;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@keyframes inputHighlighter {
  from {
    background: #fff;
  }
  to {
    width: 0;
    background: transparent;
  }
}
/****  floating-Lable style end ****/

/* last-section */
/* The checkbox start */
.last-section .form-group .container {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 12pt;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  color: #8b8f9f;
}
.last-section .form-group .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid #a6a9b5;
  border-radius: 2px;
}
.last-section .form-group .container:hover input ~ .checkmark {
  border-color: #5087c7;
}
.last-section .form-group .container input:checked ~ .checkmark {
  border-color: #5087c7;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.last-section .form-group .container input:checked ~ .checkmark:after {
  display: block;
}
.last-section .form-group .container .checkmark:after {
  left: 5px;
  top: 2px;
  width: 6px;
  height: 10px;
  border: solid #5087c7;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
/* The checkbox end */
.last-section {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0 50px 0;
}
.last-section .link-forgot a {
  text-decoration: none;
  color: #8b8f9f;
}
.login-btn {
  background: #99cc00;
  border: 1px solid #99cc00;
  padding: 10px 25px;
  border-radius: 40px;
  font-size: 20pt;
  font-family: "Roboto-Medium";
}
.login-btn:focus {
  outline: none;
}
.login-btn:hover {
  cursor: pointer;
}
.last-section-forgot {
  justify-content: flex-end;
  padding: 0 0 20px 0;
}
@media only screen and (max-width: 500px) {
  .login-page-inner {
    width: 100%;
    padding: 0 20px;
  }
  .login-page-inner .logo img {
    max-width: 100%;
  }
  .floating-input {
    padding: 15px;
    height: 60px;
  }
  .label_float {
    font-size: 18pt;
    left: 15px;
    top: 15px;
  }
}
@media only screen and (max-height: 500px) {
  .login-page {
    height: 100%;
    padding: 30px 0 0 0;
  }
}
