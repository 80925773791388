.banner-main .banner-head {
  margin: 25px 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  line-height: 38px;
  justify-content: space-between;
}
.banner-main .banner-head .banner-head-left {
  margin: 0;
  display: inherit;
}
.banner-main .banner-head .banner-head-left img{
  height: 38px;
}
.banner-main .banner-head .banner-head-left span {
  color: #4d4f5c;
  font-size: 20pt;
  font-weight: normal;
}
.banner-main .banner-head .banner-head-left span.rest-name {
  font-family: "Roboto-Bold";
}
.banner-main .banner-head .banner-head-left span.seperator {
  padding: 0 7px;
}
.banner-main .banner-head .banner-head-right {
  display: inherit;
}
/* React switch custom css start */
.react-switch-bg {
  height: 38px !important;
  width: 192px !important;
  background: #ffffff !important;
  border-radius: 6px !important;
}
.react-switch-bg div:first-child,
.react-switch-bg div:last-child {
  height: 100% !important;
  width: 50% !important;
  display: flex !important;
  justify-content: center !important;
  opacity: 1 !important;
}
.react-switch-bg div:first-child div.active,
.react-switch-bg div:last-child div.active {
  width: 100% !important;
  transition: all 0.25s ease 0s;
  font-family: "Roboto-Medium";
}
.react-switch-bg div:first-child div.active {
  border-bottom-left-radius: 6px !important;
  border-top-left-radius: 6px !important;
  background: #99cc00 !important;
}
.react-switch-bg div:last-child div.active {
  border-bottom-right-radius: 6px !important;
  border-top-right-radius: 6px !important;
  background: #a09c9c !important;
}
.react-switch-bg div:first-child div.inactive {
  border: 1px solid #a09c9c !important;
  width: 100% !important;
  border-bottom-left-radius: 6px !important;
  border-top-left-radius: 6px !important;
  box-sizing: border-box;
  font-family: "Roboto-Regular";
}
.react-switch-bg div:last-child div.inactive {
  border: 1px solid #99cc00 !important;
  width: 100% !important;
  border-bottom-right-radius: 6px !important;
  border-top-right-radius: 6px !important;
  box-sizing: border-box;
  font-family: "Roboto-Regular";
}
.react-switch-handle {
  display: none !important;
}
/* React switch custom css end */

/* card section css start */
.card-section-inner {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 0 -15px 40px;
}
.card-section-inner .card-main {
  display: inherit;
  padding: 0 12px;
  width: 25%;
}
.card-section-inner .card-main-inner {
  display: flex;
  flex: 1 1;
  align-items: center;
  justify-content: left;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(229, 229, 235, 1);
  padding: 20px 10px 20px 15px;
  box-sizing: border-box;
  border: 2px solid transparent;
  border-radius: 1px;
  max-width: 100%;
}
.card-section-inner .card-main-inner:hover {
  cursor: pointer;
  border: 2px solid #5087c7;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(173, 196, 227, 1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(173, 196, 227, 1);
  box-shadow: 0px 0px 5px 0px rgba(173, 196, 227, 1);
}
.card-section-inner .card-main-inner.active {
  border: 2px solid #5087c7;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(173, 196, 227, 1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(173, 196, 227, 1);
  box-shadow: 0px 0px 5px 0px rgba(173, 196, 227, 1);
}
.card-section-inner .card-main-inner .icon-block {
  height: 50px;
  width: 50px;
  background: #edf3f9;
  display: inherit;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 15px;
}
.card-section-inner .card-main-inner .icon-block i.fas {
  font-size: 30px;
  color: #5087c7;
}
.card-section-inner .card-main-inner .content-box {
  display: inherit;
  flex-wrap: wrap;
  align-items: center;
}
.card-section-inner .card-main-inner .content-box h3 {
  margin: 0;
  display: inherit;
  width: 100%;
  color: #a6a7ad;
  font-size: 14pt;
  padding: 0 0 10px 0;
  font-family: "Roboto-Medium";
  font-weight: normal;
}
.card-section-inner .card-main-inner .content-box span {
  font-size: 24pt;
  color: #4d4f5c;
  font-family: "Roboto-Bold";
}
/* card section css end */
