.header-main {
  background: #fff;
  position: relative;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(229, 229, 235, 1);
  -moz-box-shadow: 0px 0px 8px 0px rgba(229, 229, 235, 1);
  box-shadow: 0px 0px 8px 0px rgba(229, 229, 235, 1);
}
.header-inner {
  height: 70px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.header-inner .header-right {
  display: inherit;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}
.header-inner .header-right .user-image {
  height: 40px;
  width: 40px;
  background: #5087c7;
  border-radius: 50%;
}
.header-inner .header-right .user-details {
  position: relative;
  margin: 0 10px 0 20px;
}
.header-inner .header-right .user-details p {
  margin: 0;
  color: #747987;
}
.header-inner .header-right .user-details p:hover {
  cursor: pointer;
}
.header-inner .header-right .user-details p span {
  font-size: 16px;
  line-height: 30px;
  margin: 0 7px 0 0;
}
.ant-dropdown-trigger {
  margin: 0 10px 0 20px;
  color: #747987;
  cursor: pointer;
}
.ant-dropdown-trigger span {
  font-size: 16px;
  line-height: 30px;
  margin: 0 7px 0 0;
}
.ant-dropdown-trigger i.fas {
  font-size: 16px;
}
.logout {
  color: #7479a5;
}
.logout {
  display: block;
}
.logout:hover {
  cursor: pointer;
}
.logout i.fas {
  padding: 0 10px 0 0;
}
.header-right .action-icons {
  border-right: 1px solid #ebebf2;
  padding-right: 15px;
  line-height: 30px;
}
.header-right .action-icons i.fas {
  color: #5087c7;
  padding: 0 10px;
}
