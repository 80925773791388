/* Fonts */
/* Roboto-Regular */
@font-face {
  font-family: "Roboto-Regular";
  src: url("../src/assets/fonts/Roboto-Regular.eot") format("embedded-opentype"),
    url("../src/assets/fonts/Roboto-Regular.woff") format("woff"),
    url("../src/assets/fonts/Roboto-Regular.ttf") format("truetype"),
    url("../src/assets/fonts/Roboto-Regular.svg#Roboto-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Roboto-Bold */
@font-face {
  font-family: "Roboto-Bold";
  src: url("../src/assets/fonts/Roboto-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../src/assets/fonts/Roboto-Bold.woff") format("woff"),
    url("../src/assets/fonts/Roboto-Bold.ttf") format("truetype"),
    url("../src/assets/fonts/Roboto-Bold.svg#Roboto-Bold") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Roboto-Medium */
@font-face {
  font-family: "Roboto-Medium";
  src: url("../src/assets/fonts/Roboto-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../src/assets/fonts/Roboto-Medium.woff") format("woff"),
    url("../src/assets/fonts/Roboto-Medium.ttf") format("truetype"),
    url("../src/assets/fonts/Roboto-Medium.svg#Roboto-Medium") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap'); */

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto-Regular";
}
.wrapper-main {
  max-width: 1150px;
  margin: 0 auto;
}
body .text-danger,
body .validation-message {
  color: red;
  margin: 10px 0 0 0;
}

/* custom css */
/* search section css start */
.search-section {
  position: relative;
  margin: 0 0 50px 0;
}
.custom-section input {
  width: 92% !important;
}
.search-section input {
  width: 100%;
  padding: 5px 5px 5px 40px;
  border-radius: 6px;
  border: 1px solid #bcbccb;
  font-size: 14pt;
  font-family: "Roboto-Regular";
  color: #747987;
  display: inline-block;
}
.search-section input::placeholder {
  color: #747987;
}
.search-section input:focus {
  outline: 0;
}
.search-section:before {
  display: inline-block;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f002";
  position: absolute;
  top: 4px;
  left: 12px;
  font-size: 22px;
  color: #b0b0b0;
}
.search-section input::-webkit-search-cancel-button {
  cursor: pointer;
}
/* search section css end */

/* table css start */
.new-orders,
.active-orders,
.completed-orders,
.rejected-orders {
  padding: 0 0 50px 0;
}
.table-section .main-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
}
.table-section .main-table thead {
}
.table-section .main-table thead tr {
}
.table-section .main-table thead tr th {
  padding: 5px 15px;
  text-transform: uppercase;
  color: #69757e;
  font-size: 11pt;
  font-weight: normal;
  font-family: "Roboto-Medium";
  text-align: center;
}
.table-section .main-table tbody {
}
.table-section .main-table tbody tr.accordion-content-row {
  background: #fff;
}
.table-section .main-table tbody tr.accordion-content-row td {
  text-align: center;
}
.table-section .main-table tbody tr.main-accordion-row {
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(229, 229, 235, 1);
}
.table-section .main-table tbody tr.main-accordion-row td {
  text-align: center;
  border-spacing: 0;
  border: 0;
  padding: 25px 15px;
  font-size: 13pt;
  font-family: "Roboto-Medium";
  color: #4d4f5c;
}
.table-section .main-table tbody tr.main-accordion-row td.status {
  justify-content: center;
  align-items: center;
  display: flex;
}
.table-section .main-table tbody tr.main-accordion-row td.orderid {
  color: #5087c7;
}
.table-section .main-table tbody tr td.no_data {
  text-align: center;
  padding: 30px;
  background: #fff;
  font-size: 16px;
  font-size: 13pt;
  font-family: Roboto-Medium;
  color: #4d4f5c;
  box-shadow: 0 0 8px 0 #e5e5eb;
}
.table-section .main-table tbody tr.main-accordion-row td.status span {
  padding: 7px 15px;
  border-radius: 6px;
}
.table-section .main-table tbody tr.main-accordion-row td.status span.new {
  background: #fdf7ea;
  border: 1px solid #fdf7ea;
  color: #f7ae00;
}
.table-section
  .main-table
  tbody
  tr.main-accordion-row
  td.status
  span.completed {
  background: #f7fee3;
  border: 1px solid #f7fee3;
  color: #99cc00;
}
.table-section .main-table tbody tr.main-accordion-row td.status span.rejected {
  background: #faebeb;
  border: 1px solid #faebeb;
  color: #ff7676;
}

.table-section .main-table tbody tr.main-accordion-row td.status span.canceled {
  background: #faebeb;
  border: 1px solid #faebeb;
  color: #420000;
}
.table-section .main-table tbody tr.main-accordion-row td.actions span {
  padding: 7px 10px;
  border-radius: 6px;
  margin: 0 7px;
}
.table-section .main-table tbody tr.main-accordion-row td.actions span:hover {
  cursor: pointer;
}
.table-section .main-table tbody tr.main-accordion-row td.actions span.accept {
  background: #99cc00;
  border: 1px solid #99cc00;
  color: #000000;
}
.table-section .main-table tbody tr.main-accordion-row td.actions span.decline {
  background: #ff7676;
  border: 1px solid #ff7676;
  color: #000000;
}
.table-section .main-table tbody tr.main-accordion-row td.actions span.view {
  background: #ffffff;
  border: 1px solid #5087c7;
  color: #5087c7;
  padding: 7px 15px;
}
.table-section
  .main-table
  tbody
  tr.main-accordion-row
  td.actions
  span.view:hover {
  cursor: pointer;
}
.table-section .main-table tbody tr.main-accordion-row td.actions span.close {
  background: #5087c7;
  border: 1px solid #5087c7;
  color: #ffffff;
  padding: 7px 15px;
}
.table-section .inner-table-main {
  width: 100%;
  margin: -11px auto 0;
  border-collapse: collapse;
  border-spacing: 0px;
  display: table;
  background: #fff;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(229, 229, 235, 1);
  -moz-box-shadow: 0px 3px 3px 0px rgba(229, 229, 235, 1);
  box-shadow: 0px 3px 3px 0px rgba(229, 229, 235, 1);
}
.table-section .inner-table-main table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 95%;
  margin: 0 auto 20px;
}
.table-section .inner-table-main table thead th {
  border-top: 1px solid #bcbccb;
  border-bottom: 1px solid #bcbccb;
  font-size: 11pt;
  color: #bec3c7;
}
.table-section .inner-table-main table thead th:first-child {
  text-align: left;
  padding: 15px 0 15px 40px;
}
.table-section .inner-table-main table thead th:last-child {
  text-align: right;
  padding: 15px 40px 15px 0;
}
.table-section .inner-table-main table tbody td {
  border-bottom: 1px solid #bcbccb;
  font-size: 14pt;
  color: #4d4f5c;
  font-family: "Roboto-Medium";
}
.table-section .inner-table-main table tbody td:first-child {
  text-align: left;
  padding: 15px 0 15px 40px;
}
.table-section .inner-table-main table tbody td:last-child {
  text-align: right;
  padding: 15px 40px 15px 0;
}
.table-section .main-accordion-row span.user-image img {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 0 0;
}
.table-section .accordion-content-row-sub span.food-image img {
  height: 30px;
  width: 30px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 15px 0 0;
}
/* table css end */

/* filter section css start */
.top-head-component {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 50px 0;
}
.top-head-component .search-section {
  display: inherit;
  width: 37%;
  margin: 0;
}
.top-head-component .filter-section .btn-reset {
  margin-left: 10px;
}
.top-head-component .filter-section {
  display: inherit;
  width: 63%;
  justify-content: flex-end;
}
.top-head-component .filter-section label {
  font-size: 14pt;
  color: #747987;
  font-family: "Roboto-Bold";
  display: inherit;
  align-items: center;
}
.top-head-component .filter-section .react-datepicker-wrapper {
  display: inherit;
}
.top-head-component .filter-section .react-datepicker__input-container {
  display: inherit;
  position: relative;
}
.top-head-component .filter-section .react-datepicker__input-container input {
  border: 1px solid #bcbccb;
  padding: 3px 3px;
  width: 143px;
  border-radius: 6px;
  font-size: 14pt;
  color: #747987;
  margin: 0 10px;
  font-family: "Roboto-Regular";
}
.top-head-component .filter-section button.apply-btn {
  font-size: 14pt;
  font-family: "Roboto-Medium";
  background: #5087c7;
  border: 1px solid #5087c7;
  color: #ffffff;
  border-radius: 6px;
  padding: 0 10px;
}
.top-head-component .filter-section button.apply-btn:hover {
  cursor: pointer;
}
.top-head-component .filter-section button:focus,
.top-head-component
  .filter-section
  .react-datepicker__input-container
  input:focus {
  outline: none;
}
.top-head-component .filter-section .react-datepicker__input-container:after {
  display: inline-block;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
  content: "\f073";
  position: absolute;
  top: 1px;
  right: 22px;
  z-index: 9;
  font-size: 22px;
  color: #b0b0b0;
}
.top-head-component
  .filter-section
  .react-datepicker__input-container
  .react-datepicker-ignore-onclickoutside:after {
  display: none;
}
.loader-class {
  background: none repeat scroll 0 0 black;
  position: fixed;
  display: block;
  opacity: 0.5;
  z-index: 1000001;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.loader-class .ant-spin {
  margin: 50vh 50vw;
}
.custom-reset-btn {
  font-size: 14pt;
  font-family: "Roboto-Medium";
  background: #5087c7;
  border: 1px solid #5087c7;
  color: #ffffff;
  border-radius: 6px;
  padding: 5px 5px;
  margin-left: 10px;
  cursor: pointer;
}
/* filter section css end */
#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 158px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 2;
  cursor: pointer;
  pointer-events: none;
}
#text {
  position: absolute;
  top: 40%;
  left: 50%;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  font-family: "Roboto-Medium";
  font-size: 16pt;
  color: #000000;
  text-align: center;
  padding: 30px 15px;
  background: #e8e8ec;
  box-shadow: 0px 0px 8px 0px rgba(229, 229, 235, 1);
}
/* .overlayon {
  pointer-events: none;
} */
.overlayon .card-section-inner .card-main[data-id="new"] {
  pointer-events: none;
}
.overlayon .card-section-inner .card-main[data-id="new"] .card-main-inner {
  border: 0;
  box-shadow: none;
  opacity: 0.7;
  pointer-events: none;
}
/* .overlayon #overlay {
  display: block;
} */
.offline_mode {
  overflow: hidden;
}

/* pagination */
.pagination-block {
  display: flex;
  justify-content: flex-end;
  margin: 15px 0 0 0;
}
ul.pagination {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
  margin: 15px 0 0 0;
}
ul.pagination li {
  display: inherit;
  padding: 0 5px;
  margin: 0 10px 0 0;
  font-size: 14px;
  border-radius: 5px;
}
ul.pagination li a {
  color: #333333;
  font-size: 16px;
}
ul.pagination li:hover {
  color: #5087c7;
  cursor: pointer;
}
ul.pagination li:hover a,
ul.pagination li.active a {
  color: #5087c7;
}
ul.pagination li.active {
  color: #5087c7;
  font-weight: bold;
}
ul.pagination li:last-child {
  margin: 0;
}
ul.pagination li.next.disabled a,
ul.pagination li.previous.disabled a {
  cursor: not-allowed;
  opacity: 0.5;
}

/* media query start */
@media only screen and (max-width: 1920px) {
  .top-head-component .filter-section .react-datepicker__input-container input {
    width: 165px;
  }
}
@media only screen and (max-width: 1110px) {
  /* .wrapper-main {
    max-width: 95%;
  } */
  .active-orders
    .table-section
    .main-table
    tbody
    tr.main-accordion-row
    td.status
    span {
    padding: 7px;
  }
  .table-section .main-table tbody tr.main-accordion-row td {
    padding: 25px 5px;
  }
}
@media only screen and (max-width: 1024px) {
  .new-orders
    .table-section
    .main-table
    tbody
    tr.main-accordion-row
    td.actions
    span {
    padding: 7px 5px;
    margin: 0 3px;
  }
  .active-orders
    .table-section
    .main-table
    tbody
    tr.main-accordion-row
    td.status
    span {
    line-height: 27pt;
  }
  .card-section-inner .card-main-inner .content-box {
    width: calc(100% - 55px);
  }
  .card-section-inner .card-main-inner .icon-block {
    margin-right: 5px;
  }
  .card-section-inner .card-main-inner {
    padding: 10px;
  }
  .card-section-inner .card-main-inner .content-box h3 {
    word-break: break-word;
  }
  .card-section-inner .card-main {
    padding: 0 5px;
  }
  .card-section-inner {
    margin: 0 -5px 40px;
  }
  .top-head-component .search-section {
    width: 25%;
  }
  .top-head-component .filter-section {
    width: 75%;
  }
}
@media only screen and (max-width: 767px) {
  .card-section-inner .card-main {
    width: 50%;
    margin-bottom: 10px;
  }
  .card-section-inner {
    flex-wrap: wrap;
  }
  .table-section .main-table {
    width: 900px;
  }
  .table-section {
    overflow-x: auto;
  }
  .top-head-component .search-section {
    width: 100%;
    margin: 0 0 20px 0;
  }
  .top-head-component .filter-section {
    width: 100%;
  }
  .top-head-component {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 599px) {
  .banner-main .banner-head {
    flex-wrap: wrap;
    margin: 15px 0;
  }
  .banner-main .banner-head .banner-head-left {
    margin: 0 0 15px 0;
    width: 100%;
  }
  .card-section-inner .card-main {
    width: 100%;
  }
  .card-section-inner {
    margin: 0 -5px 15px;
  }
  .search-section {
    margin: 0 0 15px 0;
  }
  .top-head-component .filter-section {
    width: 100%;
    flex-wrap: wrap;
  }
  .top-head-component .filter-section label {
    margin: 0 0 10px 0;
    width: 100%;
  }
  .top-head-component .filter-section .react-datepicker-wrapper {
    width: 100%;
    align-items: flex-start;
  }
  .top-head-component .filter-section .react-datepicker__input-container {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .top-head-component .filter-section .react-datepicker__input-container input {
    width: 100%;
    margin: 0;
  }
  .top-head-component .filter-section button.apply-btn {
    padding: 0 20px;
    height: 45px;
  }
  #overlay {
    top: 204px;
  }
  #text {
    width: 90%;
    top: 35%;
    padding: 15px;
    font-size: 16pt;
  }
  .ReactModal__Overlay .ReactModal__Content {
    width: 90%;
    left: 0 !important;
    right: 0 !important;
    top: 10vh !important;
  }
  .ReactModal__Overlay .ReactModal__Content .reject-reason-form {
    width: calc(90% - 16px);
  }
  .ReactModal__Overlay
    .ReactModal__Content
    .reject-reason-form
    .btn-section
    button {
    margin: 0 5px;
  }
  .ReactModal__Overlay .ReactModal__Content .reject-reason-form .btn-section {
    padding: 0 10px 30px;
  }
}
@media only screen and (max-width: 450px) {
  .header-inner {
    height: 90px;
    flex-wrap: wrap;
    justify-content: center;
  }
  .header-inner .header-left {
    padding: 10px 0;
    display: inherit;
    width: 100% !important;
    justify-content: center;
  }
  .header-inner .header-right {
    justify-content: center;
    padding: 0 0 10px;
  }
}

/* Loader design */
.ex_spin {
  text-align: center;
  background: white;
  border-radius: 4px;
  margin: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f5f5f580;
  height: 100%;
  z-index: 9999;
}
.ex_spin .spin_div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

@media (max-width: 5000px) {
  .ex_spin .spin_div img {
    width: 200px;
  }
}
@media (max-width: 1600px) {
  .ex_spin .spin_div img {
    width: 150px;
  }
}
@media (max-width: 992px) {
  .ex_spin .spin_div img {
    width: 100px;
  }
}
@media (max-width: 568px) {
  .ex_spin .spin_div img {
    width: 70px;
  }
}

.menuIconClick {     
  margin-right: 5px;
  padding-top: 10px;
  font-size: 18px;
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translateY(-50%);
  padding: 0;
  margin: 0;
}

.menuIconClick + img {     
  padding-left: 2rem;
}

.header-inner { padding-left: 15px; }
.leftSidebar, .rightSidebar { transition: all 400ms ease-in-out}
.hide .leftSidebar aside {  max-width: 70px !important; 
  min-width: 70px !important;
  width: 70px !important; }
.hide .rightSidebar { width:90%; }
.hide .sidebarLogo span { display:none; }
.hide .sidebarLogo img { display:inline-block; max-width:50px }
.ant-menu-inline-collapsed { width:100% !important}
html body .hide .ant-menu-item {    padding: 0 24px !important;}